import React from "react";

const FormulasPage = () => {
  return (
    <div>
      <div id="basic">Basic</div>
      <div id="personalized">Personalized</div>
      <div id="all-in">All-in</div>
    </div>
  );
};

export default FormulasPage;
